// These vars apply to the FIRST set of imported Bootstrap sets

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$theme-color: #930027 !default;
$secondary: #930027 !default;
$blue: #007bff !default;
$background: white !default;

$carousel-control-color: $theme-color;

$enable-responsive-font-sizes: true;
$enable-gradients: true;
$enable-shadows: true;
$enable-validation-icons: false;

$grid-gutter-width: 1.02rem;// !default;

$input-padding: 0.375rem !default;
$input-border-color: #ced4da !default;

$dropdown-item-padding-x: $input-padding;
$dropdown-padding-y: $input-padding;
$dropdown-padding-x: $input-padding;
$dropdown-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

$print-page-size: auto;
$theme-colors: (
  primary: $theme-color,
  secondary: $secondary,
  main: $theme-color,
  background: $background,
  "blue": $blue
);

// Darkred Default Style Variables
$prefix: dr;

:root {
  --#{$prefix}-border-color: #{$input-border-color};
  --#{$prefix}-border-radius: 0.25rem;
  --#{$prefix}-border-width: 1px;
  --#{$prefix}-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --#{$prefix}-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --#{$prefix}-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --#{$prefix}-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --#{$prefix}-box-shadow-none: none;
}

$darkred-grey: #6c757d;
$light-grey: #e9ecef;
$base-grey: rgba(0, 0, 0, 0.87);


%nav-link-spacing {
  @include media-breakpoint-up(md) {
    margin-right: 0.5rem;
  }
}

%nav-link-behaviour {
  &.active {
    @extend %nav-link-hover;
  }
  cursor: pointer;
  color: var(--primary);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

%nav-link-hover {
  color: var(--white) !important;
  background-color: var(--primary);
  // border-color: var(--primary);
}
